import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import styled, { ThemeProvider } from 'styled-components';
import { ResetCSS } from 'common/source/assets/css/style';
import { theme } from 'common/source/styles/theme';
import { GlobalStyle, ContentWrapper } from 'common/source/styles';
import Navbar from 'common/source/containers/SolarNavbar';
import Banner from 'common/source/containers/Banner';
// import SolutionsGrid from 'common/source/containers/SolutionsGrid';
import SolutionsGrid from 'common/source/containers/SolutionsGridList';
import SolutionList from 'common/source/containers/SolutionList';

// import ResourcesBanner from 'common/source/containers/ResourcesBanner';
import ResourcesBanner from 'common/source/containers/ResourcesBannerList';
import CTABanner from 'common/source/containers/CTABanner';

// import BlogSection from 'common/source/containers/BlogSection';
// import TimelineSection from 'common/source/containers/TimelineSection';
// import ContactSections from 'common/source/containers/Contact';
// import PartnerSection from 'common/source/containers/Partner';
// import FeatureSection from 'common/source/containers/FeatureSection';
import Footer from 'common/source/containers/Footer';
import Container from 'common/source/components/UI/Container';

import SEO from '../../components/SEO';
// import PageContent from '../../components/PageContent';
import config from '../../../data/SiteConfig';

const InnerContentWrapper = styled.div`
  margin-bottom: 3rem;
  .heading {
    text-align: center;
    padding: 2rem;
    h2,
    h3 {
      font-size: 2.25rem;
      font-weight: 500;
      color: ${props => props.theme.colors.headingColor};
      line-height: 1.25;
      @media (max-width: 768px) {
        font-size: 2rem;
      }
    }
  }
`;

class Utilitario extends React.Component {
  render() {
    const { data } = this.props;
    const { page, resources, solutions } = data;
    const { html, frontmatter } = page;
    const allResources = resources.edges.map(edge => edge.node);
    const allSolutions = solutions.edges.map(edge => edge.node);
    const haveSolutions = allSolutions.length > 0;
    return (
      <ThemeProvider theme={theme}>
        <Fragment>
          <SEO
            pageTitle={`${frontmatter.title} | ${config.siteTitle}`}
            path={`/${frontmatter.slug}`}
            pageNode={page}
          />

          <ResetCSS />
          <GlobalStyle />

          <ContentWrapper>
            <Navbar />
            <Banner background="blue" cover={frontmatter.cover} heading={frontmatter.title}>
              {frontmatter.subtitle}
            </Banner>
            <Container>
              <InnerContentWrapper>
                <div className="heading">
                  <h2>Aplicaciones profesionales para uso utilitario</h2>
                </div>
                {!haveSolutions && (
                  <SolutionsGrid path="utilitario" categories={frontmatter.categories} />
                )}
                <div dangerouslySetInnerHTML={{ __html: html }} />
                <SolutionList title="Uso Utilitario" solutions={allSolutions} top />
                <ResourcesBanner heading={frontmatter.title} resources={allResources} />
              </InnerContentWrapper>
            </Container>
            <CTABanner />
            <Footer />
          </ContentWrapper>
        </Fragment>
      </ThemeProvider>
    );
  }
}
export default Utilitario;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query UtilitarioQuery {
    page: markdownRemark(frontmatter: { slug: { eq: "utilitario" } }) {
      html
      timeToRead
      excerpt
      fields {
        slug
        date
      }
      frontmatter {
        slug
        title
        subtitle
        type
        cover
        thumbnail
        categories {
          category_slug
          title
          subtitle
          featured
          cover
          thumbnail
        }
      }
    }
    solutions: allMarkdownRemark(
      filter: {
        frontmatter: {
          category: { in: ["emergencias", "general", "telecomunicaciones"] }
          type: { eq: "SOLUTION" }
        }
      }
      sort: { order: DESC, fields: [frontmatter___featured] }
    ) {
      edges {
        node {
          excerpt
          frontmatter {
            slug
            title
            cover
            thumbnail
            featured
            tags
          }
        }
      }
    }
    resources: allMarkdownRemark(
      filter: {
        frontmatter: { type: { eq: "RESOURCE" }, category: { in: ["ornamental", "senderos"] } }
      }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            slug
            title
            cover
            thumbnail
            tags
          }
        }
      }
    }
  }
`;
